var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"profile-cover",attrs:{"extended":"","extension-height":"196","dark":"","flat":"","color":_vm.environment.accentColor}},[_c('StatusBar',{attrs:{"statusStyle":"lightcontent","backgroundColor":"#66000000"}}),(
      _vm.sidebar && ((_vm.path && _vm.path !== '/') || _vm.$vuetify.breakpoint.mdAndDown)
    )?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('sidebarToggle')}}}):_vm._e(),_c('v-toolbar-title',{domProps:{"textContent":_vm._s(_vm.title)}}),_c('v-spacer'),_c('v-btn',{class:{
      'notifications-btn': true,
      'has-notifications': _vm.notificationCount > 0
    },staticStyle:{"min-width":"16px","padding":"2px 8px"},attrs:{"text":_vm.notificationCount <= 0,"depressed":_vm.notificationCount > 0,"color":_vm.buttonColor,"title":"Notificaciones"},on:{"click":function($event){return _vm.$emit('notificationsToggle')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("notifications_none")]),_vm._v(" "+_vm._s(_vm.notificationCount || '0')+" ")],1),(_vm.environment && _vm.environment.enableTellus)?_c('v-btn',{class:{
      'tellus-btn': true,
      'has-messages': _vm.unreadMessageCount > 0
    },attrs:{"color":_vm.buttonColor,"title":"Soporte","text":""},on:{"click":function($event){return _vm.$emit('messagesToggle')}}},[_c('v-icon',{attrs:{"left":_vm.unreadMessageCount > 0}},[_vm._v("question_answer")]),(_vm.unreadMessageCount > 0)?_c('span',{domProps:{"textContent":_vm._s(_vm.unreadMessageCount)}}):_vm._e()],1):_vm._e(),_c('v-menu',{attrs:{"transition":"slide-y-transition","nudge-width":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-avatar',{attrs:{"color":"grey lighten-4","size":32}},[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"Avatar"}})])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
              _vm.user &&
              (_vm.user.profile.name ||
                _vm.envProfile.name ||
                _vm.envProfile.nombre ||
                _vm.user.email)
            )}}),(
              _vm.user &&
              (_vm.user.profile.name || _vm.envProfile.name || _vm.envProfile.nombre)
            )?_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.user.email)}}):_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(_vm.userRoleDisplay)}})],1),_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.userAvatar,"alt":"Avatar"}})])],1),_c('v-divider',{staticStyle:{"margin":"0.5em 0"}}),_vm._l((_vm.userMenu),function(link){return _c('v-list-item',{key:link._id,attrs:{"title":link.title,"to":link.path}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(link.title)}})],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(_vm._f("fixLegacyIcons")(link.icon)))])],1)],1)}),(_vm.userMenu.length)?_c('v-divider',{staticStyle:{"margin":"0.5em 0"}}):_vm._e(),_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Opciones")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("settings")])],1)],1),(
          !_vm.desktopApp &&
          (_vm.isAdmin ||
            _vm.environmentUser.canAddUsers ||
            _vm.environmentUser.canRemoveUsers ||
            _vm.environmentUser.canEditProfiles ||
            _vm.environmentUser.canManageRoles)
        )?_c('v-list-item',{attrs:{"to":"/manage/users"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Administrar Usuarios")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("group")])],1)],1):_vm._e(),(_vm.isAdmin && _vm.adminLink)?_c('v-list-item',{attrs:{"href":_vm.adminLink,"target":"_blank"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Panel de Administración")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("dashboard")])],1)],1):_vm._e(),(_vm.isAdmin && _vm.adminLink)?_c('v-list-item',{on:{"click":_vm.toggleEditLinks}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Links de Edición")])],1),_c('v-list-item-avatar',[(_vm.$store.state.app.editLinks)?_c('v-icon',{attrs:{"color":"primary","size":"48"}},[_vm._v("toggle_on")]):_c('v-icon',{attrs:{"size":"48"}},[_vm._v("toggle_off")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("edit")])],1)],1):_vm._e(),(_vm.environment.allowDarkMode)?_c('v-list-item',{on:{"click":_vm.toggleDarkMode}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Modo oscuro")])],1),_c('v-list-item-avatar',[(_vm.$store.state.app.darkMode)?_c('v-icon',{attrs:{"color":"primary","size":"48"}},[_vm._v("toggle_on")]):_c('v-icon',{attrs:{"size":"48"}},[_vm._v("toggle_off")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("brightness_medium")])],1)],1):_vm._e(),(!_vm.desktopApp)?_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1),_c('v-list-item-avatar',[_c('v-icon',[_vm._v("exit_to_app")])],1)],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }